import { useTranslation } from "react-i18next";

import FormField from "../FormField";
import Input from "../Input";
import MobileNumberInput from "../MobileNumberInput";
import { FormikProps } from "formik";

import { CreditCardFormValues } from "../../types/credit-card";

export type CreditCardUserDetailsProps = {
  countryCode: string;
} & FormikProps<CreditCardFormValues>;

const CreditCardUserDetails = (props: CreditCardUserDetailsProps) => {
  const { t } = useTranslation("forms");

  return (
    <div className="space-y-2">
      {/* cardholder details */}
      <div className="flex -mx-2">
        <div className="w-1/2 px-2">
          <FormField
            label={t("First Name")}
            name="givenName"
            helpText={
              props.touched.givenName && props.errors.givenName
                ? t(props.errors.givenName as string, {
                    field: t("First Name")
                  })
                : undefined
            }
            state={
              props.touched.givenName && props.errors.givenName
                ? "error"
                : "default"
            }
          >
            <Input
              block
              disabled={props.isSubmitting}
              autoComplete="given-name"
              name="givenName"
              value={props.values.givenName}
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              hasError={props.touched.givenName && !!props.errors.givenName}
              placeholder="John"
              data-testid="first-name"
            />
          </FormField>
        </div>
        <div className="w-1/2 px-2">
          <FormField
            label={t("Last Name")}
            name="surname"
            helpText={
              props.touched.surname && props.errors.surname
                ? t(props.errors.surname as string, {
                    field: t("Last Name")
                  })
                : undefined
            }
            state={
              props.touched.surname && props.errors.surname
                ? "error"
                : "default"
            }
          >
            <Input
              block
              disabled={props.isSubmitting}
              autoComplete="family-name"
              name="surname"
              value={props.values.surname}
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              hasError={props.touched.surname && !!props.errors.surname}
              placeholder="Doe"
              data-testid="surname"
            />
          </FormField>
        </div>
      </div>
      <FormField
        label={t("Email Address")}
        name="email"
        helpText={
          props.touched.email && props.errors.email
            ? t(props.errors.email as string, {
                field: t("Email Address")
              })
            : undefined
        }
        state={props.touched.email && props.errors.email ? "error" : "default"}
      >
        <Input
          block
          disabled={props.isSubmitting}
          autoComplete="email"
          name="email"
          value={props.values.email}
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          hasError={props.touched.email && !!props.errors.email}
          placeholder="payer@xendit.co"
          data-testid="email"
        />
      </FormField>
      <MobileNumberInput defaultCountryCode={props.countryCode} {...props} />
    </div>
  );
};

export default CreditCardUserDetails;
